import Debug from 'debug';
import Header from '@atlas/atlas-communication-header';
import pako from 'pako';

// const Debug = require('debug');
// const Header = require('@atlas/atlas-communication-header');
// const pako = require('pako');

/* eslint-disable func-names, max-len */
const debug = Debug('AtlasHttp:Lib');
const verbose = Debug('Verbose:AtlasHttp:Lib');
const maxLoadTimeDefaultSec = (20 * 60); // 20 minutes
const AtlasHeader = new Header.Header();
const CorrelationHeaderName = 'X-Siemens-Correlation-Id';
const clientVersionHeaderName = 'x-atlas-httpclient-version';
const clientVersion = 3;
const dataTypeJson = 'json';
const dataTypeAtlasBinary = 'atlas-binary';
const dataTypeBinary = 'binary';

function AtlasHttp(options = {}) {
  this.options = options;
  if (!this.options.maxLoadTimeSec) this.options.maxLoadTimeSec = maxLoadTimeDefaultSec;
  this.validHttpStatus = [200, 202, 204, 206];
  if (!this.options.dataType) this.options.dataType = dataTypeAtlasBinary;
  const validDataTypes = [dataTypeAtlasBinary, dataTypeJson, dataTypeBinary];
  if (!validDataTypes.includes(this.options.dataType)) {
    this.options.dataType = dataTypeAtlasBinary;
  }
  if (!this.options.jrm || !this.options.jrm.baseUrl) {
    this.options.useJrm = false;
  } else {
    this.options.useJrm = true;
  }
}

AtlasHttp.prototype.makeParam = function (k, v) {
  return { key: k, value: v };
};

AtlasHttp.prototype.encodeParams = (p) => p
  .filter((kvp) => kvp.key && kvp.value)
  .map((kvp) => `${kvp.key}=${encodeURIComponent(kvp.value)}`)
  .join('&');

AtlasHttp.prototype.isArrayBuffer = function (value) {
  return value && value.buffer instanceof ArrayBuffer && value.byteLength !== undefined;
};

AtlasHttp.prototype.isString = function (s) {
  return (typeof s === 'string' || s instanceof String);
};

/* eslint-disable */
AtlasHttp.prototype.uuidv4 = function () {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
/* eslint-enable */
/* eslint-disable func-names, max-len */

AtlasHttp.prototype.getHeaderSize = (d) => AtlasHeader.getBufferSize(d);
AtlasHttp.prototype.decodeHeader = (d) => AtlasHeader.decodeHeader(d);

AtlasHttp.prototype.getUrl = function (action, params = []) { // , urlOptions = {}) {
  // const { protocol } = window.location;
  // let { host } = window.location;

  // if (urlOptions.host) {
  //   ({ host } = urlOptions);
  // }

  // encode url parameters
  const urlPart = this.options.service ? `/${this.options.service}` : '';
  const url = `${urlPart}${action}?${this.encodeParams(params)}`;
  return url;
};

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["xhttp"] }] */
AtlasHttp.prototype.setupRequest = function (api, xhttp, body, headers) {
  let corrIdUsed = false;
  let contentTypeUsed = false;

  headers.forEach((h) => {
    if (h.name && h.value && this.isString(h.name) && this.isString(h.value)) {
      if (h.name.toLowerCase() === 'cors-with-credentials') {
        if (h.value.toLowerCase() === 'true') {
          xhttp.withCredentials = true;
          if (console && console.log) {
            console.log('Using withCredentials option in xhr');
          }
        }
      } else {
        xhttp.setRequestHeader(h.name, h.value);
        if (h.name.toLowerCase() === 'x-siemens-correlation-id') {
          corrIdUsed = true;
          debug(`Using passed correlation id in ${api} call`, h.value);
        }
        if (h.name.toLowerCase() === 'content-type') contentTypeUsed = true;
      }
    }
  });
  if (body && !contentTypeUsed) {
    if (this.isArrayBuffer(body)) xhttp.setRequestHeader('Content-Type', 'application/octet-stream');
    else xhttp.setRequestHeader('Content-Type', 'application/json');
  }
  if (!corrIdUsed) {
    const newCorrId = this.uuidv4();
    xhttp.setRequestHeader(CorrelationHeaderName, newCorrId);
    debug(`Generating correlation id in ${api} call`, newCorrId);
    if (console && console.log) {
      console.log(`Generating correlation id in ${api} call`, newCorrId);
    }
  }
  xhttp.setRequestHeader(clientVersionHeaderName, clientVersion);
};

/*
* headers parameter is an array of object of type {name: "", value: ""}
*/
AtlasHttp.prototype.sendApi = function (action, body, params = [], headers = [], timeoutSec = 30) {
  const self = this;
  return new Promise((resolve, reject) => {
    const timerId = setTimeout(() => reject(new Error(`Timeout in waiting for response from ${action}`)), timeoutSec * 1000);
    const xhttp = new XMLHttpRequest();
    const url = self.getUrl(action, params);
    xhttp.open('POST', url, true);
    self.setupRequest('sendApi', xhttp, body, headers);
    // let corrIdUsed = false;
    // let contentTypeUsed = false;
    // headers.forEach((h) => {
    //   if (h.name && h.value && self.isString(h.name) && self.isString(h.value)) {
    //     xhttp.setRequestHeader(h.name, h.value);
    //     if (h.name.toLowerCase() === 'x-siemens-correlation-id') {
    //       corrIdUsed = true;
    //       debug('Using passed correlation id in sendApi call', h.value);
    //     }
    //     if (h.name.toLowerCase() === 'content-type') contentTypeUsed = true;
    //   }
    // });
    // if (!contentTypeUsed) {
    //   if (self.isArrayBuffer(body)) xhttp.setRequestHeader('Content-Type', 'application/octet-stream');
    //   else xhttp.setRequestHeader('Content-Type', 'application/json');
    // }
    // if (!corrIdUsed) {
    //   const newCorrId = self.uuidv4();
    //   xhttp.setRequestHeader(CorrelationHeaderName, newCorrId);
    //   debug('Generating correlation id in sendApi call', newCorrId);
    //   if (console && console.log) {
    //     console.log('Generating correlation id in sendApi call', newCorrId);
    //   }
    // }
    xhttp.onload = function () {
      if (xhttp.status >= 200 && xhttp.status < 300) {
        clearTimeout(timerId);
        resolve({ status: xhttp.status, response: xhttp.response });
      } else {
        clearTimeout(timerId);
        reject(new Error(xhttp.responseText));
      }
    };
    xhttp.onerror = function (event) {
      clearTimeout(timerId);
      if (console && console.log) {
        console.log('onError handler', event);
      }
      reject(new Error(`${xhttp.status} ${xhttp.statusText} - Network error in sendApi`));
    };
    // Note: JSON data must already be stringified before calling sendApi
    xhttp.send(body);
  });
};

/*
    This function is called when using to poll for data using Atlas Service poll response pattern
    204 -> no data available
    206- > partial data sent, more data available
    200 -> all data sent

    jobId is a mandatory parameter. Direct parameter dont pass it as part of params
    LastData-Id parameter sent in response must be sent as query parameter to the next polling call
*/
AtlasHttp.prototype.poll = function (action, jobId, params, headers) {
  const self = this;
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();

    let url;
    if (self.options.useJrm) {
      url = `${self.options.jrm.baseUrl}/job/${jobId}/data`;
      if (params && params.length > 0) {
        url += `?${self.encodeParams(params)}`;
      }
    } else {
      // Keeping 'id' param for old vis versions. It will be removed once vis service stops supporting 'id'.
      params.push(self.makeParam('id', jobId));
      params.push(self.makeParam('jobId', jobId));

      url = self.getUrl(action, params);
    }
    debug('Requesting http download using url', url, '. Using JRM:', self.options.useJrm);

    request.open('GET', url, true);
    self.setupRequest('poll', request, undefined, headers);

    request.responseType = self.options.useJrm ? 'text' : 'arraybuffer';
    request.onreadystatechange = function () {
      if (request.readyState === XMLHttpRequest.DONE) {
        debug(`Received ${request.status} for poll`, jobId);
        const httpRes = {
          status: request.status,
          nextId: request.getResponseHeader('Lastdata-Id') || request.getResponseHeader('LastData-Id'),
          pagesReceived: parseInt(request.getResponseHeader('Pages-Sent') || '0', 10),
          totalPages: parseInt(request.getResponseHeader('Total-Pages') || '0', 10),
          pagesArray: JSON.parse(request.getResponseHeader('Pages-Array') || '{}'),
        };
        if (self.options.useJrm) {
          httpRes.pageInfo = request.getResponseHeader('Additional-Info') ? JSON.parse(request.getResponseHeader('Additional-Info')) : null;
        } else {
          httpRes.pageInfo = request.getResponseHeader('Load-Status') ? JSON.parse(request.getResponseHeader('Load-Status')) : null;
        }
        if (request.getResponseHeader('Pages-Array')) {
          try {
            httpRes.pagesArray = JSON.parse(request.getResponseHeader('Pages-Array'));
          } catch (error) {
            httpRes.pagesArray = [];
            debug('Error parsing Pages-Array http header.', error);
          }
        }
        verbose(httpRes);

        if (self.validHttpStatus.includes(request.status)) {
          if (request.status === 200 || request.status === 206) {
            httpRes.data = request.response;
          } else if (request.status === 202 || request.status === 204) {
            httpRes.data = null;
          }
          if (self.options.isJrm) {
            debug('Response javascript type', typeof httpRes.data);
          }
          resolve(httpRes);
        } else {
          let responseText = this.response.responseText; // eslint-disable-line  prefer-destructuring
          if (this.response.length && this.response.length > 0) responseText = this.response;
          else if (this.response.byteLength && this.response.byteLength > 0) responseText = String.fromCharCode.apply(null, new Uint8Array(this.response));
          console.log('response status/text', httpRes.data, request.status, responseText);
          reject(new Error(`Failure response from server. ${responseText}`));
        }
      }
    };
    request.send();
  });
};

AtlasHttp.prototype.processJsonData = function (jobId, data, pagesArray, totalPages) {
  try {
    if (this.options.onJsonData) {
      const jsonData = JSON.parse(data);
      this.options.onJsonData(jobId, jsonData, pagesArray, totalPages);
    }
  } catch (error) {
    debug('=== Error processing json data===', error);
    throw error;
  }
};

AtlasHttp.prototype.processTextData = function (jobId, data, pagesArray, totalPages) {
  try {
    if (this.options.onTextData) {
      this.options.onTextData(jobId, data, pagesArray, totalPages);
    }
  } catch (error) {
    debug('=== Error processing text data===', error);
    throw error;
  }
};

AtlasHttp.prototype.processBinaryData = function (jobId, data, pagesArray, totalPages) {
  try {
    if (this.options.onBinaryData) {
      this.options.onBinaryData(jobId, data, pagesArray, totalPages);
    }
  } catch (error) {
    debug('=== Error processing binary data===', error);
    throw error;
  }
};

AtlasHttp.prototype.processAtlasBinaryData = function (jobId, data) {
  try {
    let moreData = true;
    let byteArray = data;
    while (moreData) {
      if (byteArray.byteLength < 16) {
        debug('Not enough data to parse the header');
        break;
      }

      const header = this.decodeHeader(byteArray);
      const headerSize = this.getHeaderSize(byteArray);
      const msgSize = header.getSize();
      const nextSegmentOffset = headerSize + msgSize;
      if (byteArray.byteLength < nextSegmentOffset) {
        debug(`Not enough data to generate graphics, require more ${nextSegmentOffset - byteArray.byteLength} bytes!!`);
        break;
      }

      const body = new Uint8Array(byteArray.slice(headerSize, nextSegmentOffset));
      let inflate = body;

      if (header.isCompress()) {
        inflate = pako.inflate(body);
      }

      if (header.isGraphicsStructure() && this.options.onStructure) {
        this.options.onStructure(jobId, inflate, header);
      } else if (header.isGraphicsGeometry() && this.options.onGeometry) {
        this.options.onGeometry(jobId, inflate, header);
      }

      byteArray = byteArray.slice(nextSegmentOffset);
      moreData = byteArray.byteLength !== 0;
    }
  } catch (error) {
    debug('=== Error processing atlas binary data===', error);
    throw error;
  }
};

AtlasHttp.prototype.processHttpData = function (jobId, data, pagesArray, totalPages) {
  debug('process http data called', typeof data, data, pagesArray, totalPages);
  if (typeof data === 'string') {
    if (this.options.dataType === dataTypeJson) {
      this.processJsonData(jobId, data, pagesArray, totalPages);
    } else {
      this.processTextData(jobId, data, pagesArray, totalPages);
    }
  } else {
    if (this.options.dataType === dataTypeAtlasBinary) { // eslint-disable-line no-lonely-if
      this.processAtlasBinaryData(jobId, data, pagesArray, totalPages);
    } else {
      this.processBinaryData(jobId, data, pagesArray, totalPages);
    }
  }
};

/*
* headers parameter is an array of object of type {name: "", value: ""}
*/
AtlasHttp.prototype.getData = function (action, jobId, headers = [], optionalBackOffValues = []) {
  let nextId;
  let backOff = [1000, 2000, 2000, 4000];
  if (optionalBackOffValues && Array.isArray(optionalBackOffValues) && optionalBackOffValues.length > 0) backOff = optionalBackOffValues;
  let backOffIndex = -1;
  const startTime = +new Date();
  const timoutTime = startTime + (this.options.maxLoadTimeSec * 1000);
  const self = this;

  const clientPages = {
    pagesLoaded: 0,
  };

  (async function dataLoop() {
    try {
      if (+new Date() > timoutTime) {
        throw new Error('Max load time exceeded. Aborting open operation');
      }
      if (self.options.continueLoad && typeof self.options.continueLoad === 'function' && self.options.continueLoad() === false) {
        throw new Error('Load aborted by client. Stopping poll for data.');
      }
      const params = [];
      if (nextId) {
        if (self.options.useJrm) {
          params.push(self.makeParam('next-id', nextId));
        } else {
          params.push(self.makeParam('nextId', nextId));
        }
      }

      const httpResult = await self.poll(action, jobId, params, headers);
      if (httpResult.pagesReceived > 0) {
        clientPages.pagesLoaded += httpResult.pagesReceived;
        debug('Total pages received:', clientPages.pagesLoaded);
      }

      if (httpResult.status === 200 || httpResult.status === 202) { // data may be available in response
        if (!httpResult.nextId) throw new Error('Server did not return nextid for 200 status');
        nextId = httpResult.nextId;
        backOffIndex = -1;
        if (httpResult.data) {
          if (typeof httpResult.data === 'string' && httpResult.data.length > 0) self.processHttpData(jobId, httpResult.data, httpResult.pagesArray, httpResult.totalPages);
          else if (httpResult.data.byteLength > 0) self.processHttpData(jobId, httpResult.data, httpResult.pagesArray, httpResult.totalPages);
          else console.log('No data callback made as data is not of expected type', typeof httpResult.data);
        }
        if (self.options.onProgress && httpResult.pageInfo) {
          httpResult.pageInfo.pagesLoaded = clientPages.pagesLoaded;
          self.options.onProgress(httpResult.pageInfo);
        }
        if (self.options.useJrm) {
          console.log('Poll status (jrm)', new Date().toISOString(), httpResult.totalPages, clientPages.pagesLoaded);
          if (httpResult.totalPages !== 0 && httpResult.totalPages === clientPages.pagesLoaded) {
            if (self.options.onComplete) self.options.onComplete(jobId);
            return;
          }
        } else {
          console.log('Poll status', new Date().toISOString(), httpResult.pageInfo.totalEnsPages, clientPages.pagesLoaded);
          if (httpResult.pageInfo.totalEnsPages !== 0 && httpResult.pageInfo.totalEnsPages === clientPages.pagesLoaded) {
            if (self.options.onComplete) self.options.onComplete(jobId);
            return;
          }
        }
        setTimeout(dataLoop, Math.floor(Math.random() * 500)); // call within the next half second for more data
      } else if (httpResult.status === 204) { // no additional data available. keep polling until other status or timeout
        if (self.options.onProgress && httpResult.pageInfo) { // update progess alone as there could be other info sent in Last-Status header (like bom expansion time etc.)
          httpResult.pageInfo.pagesLoaded = clientPages.pagesLoaded;
          self.options.onProgress(httpResult.pageInfo);
        }

        if (self.options.useJrm) {
          console.log('Poll status (jrm) (204 response)', new Date().toISOString(), httpResult.totalPages, clientPages.pagesLoaded);
          if (httpResult.totalPages !== 0 && httpResult.totalPages === clientPages.pagesLoaded) {
            if (self.options.onComplete) self.options.onComplete(jobId);
            return;
          }
        } else {
          console.log('Poll status (204 response)', new Date().toISOString(), httpResult.pageInfo.totalEnsPages, clientPages.pagesLoaded);
          if (httpResult.pageInfo.totalEnsPages !== 0 && httpResult.pageInfo.totalEnsPages === clientPages.pagesLoaded) {
            if (self.options.onComplete) self.options.onComplete(jobId);
            return;
          }
        }
        nextId = httpResult.nextId;
        backOffIndex += 1;
        if (backOffIndex >= backOff.length) backOffIndex = backOff.length - 1; // stay at max backoff until there is more data
        setTimeout(dataLoop, backOff[backOffIndex] + Math.floor(Math.random() * 250)); // add 250ms jitter
      } else { // unhandled status. treat as error
        throw new Error(`unknown status received. status: ${httpResult.status}`);
      }
    } catch (err) {
      console.log('=== Error getting HTTP data ===', err);
      if (self.options.onError) self.options.onError(jobId, err);
    }
  }());
};

export default { Client: AtlasHttp, AtlasBinaryTypeName: dataTypeAtlasBinary };
